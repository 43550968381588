$rhap_theme-color: #FE2472;
$rhap_font-family: Lato !default;
$rhap_background-color: #fff !default;
$small: 600px;

@import "node_modules/react-h5-audio-player/src/styles.scss";

.rhap_container {
  border-radius: 16px;
  width: 60%;
  @media screen and (max-width: $small) {
    width: 80%;
  }
}

.rhap_main-controls-button {
  font-size: 30px;
  width: 30px;
  height: 30px;
}

.rhap_volume-button {
  font-size: 21px;
  width: 21px;
  height: 21px;
}

.rhap_repeat-button {
  font-size: 21px;
  width: 21px;
  height: 21px;
}

.rhap_time {
  font-size: 11px;
}

.rhap_progress-indicator {
  top: -5px;
  width: 15px;
  height: 15px;
}
