.rcw-widget-container {
 margin: 0 50px 50px 0;
 font-family: "Lato", sans-serif;
}

.rcw-launcher {
 background-color: #9C26B0;
}

.rcw-conversation-container .rcw-header {
 background-color: #9C26B0;
}

.rcw-client .rcw-message-text {
 background-color: #9C26B0;
 color: #FFFFFF;
}
